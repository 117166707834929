import { render, staticRenderFns } from "./ItemListView2.vue?vue&type=template&id=5cbdc181&scoped=true&"
import script from "./ItemListView2.vue?vue&type=script&lang=js&"
export * from "./ItemListView2.vue?vue&type=script&lang=js&"
import style0 from "./ItemListView2.vue?vue&type=style&index=0&id=5cbdc181&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbdc181",
  null
  
)

export default component.exports